import React from "react";
import {List} from "react-admin";
import {CustomPagination} from "../../common/pagination/CustomPagination";
import UGroupUsersFilter from "./list/UGroupUsersFilter";
import UGroupUsersGrid from "./list/UGroupUsersGrid";

export default ({permissions: p, editable, ...props}) => (
  <List
    {...props}
    title="Users"
    resource='ugroup_users'
    basePath='/ugroup_users'
    hasCreate={false}
    {...(!editable ? {bulkActionButtons: false} : {})}
    sort={{field: 'user_id', order: 'ASC'}}
    pagination={<CustomPagination/>}
    perPage={50}
    filter={{ugroup_id: props.id}}
    filters={<UGroupUsersFilter/>}
  >
    <UGroupUsersGrid/>
  </List>
);