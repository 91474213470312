import React from "react";
import {
  DeleteButton,
  SaveButton,
  Toolbar,
} from "react-admin";
import CancelButton from "./CancelButton";

export default ({...props}) => {
  const {record: {id}} = props;
  const isCreate = !id;

  return isCreate
    ? <CreateToolbar {...props}/>
    : <EditToolbar {...props}/>;
};

const CreateToolbar = ({
                         showSave = true,
                         showSaveBack = true,
                         showCancel = true,
                         saveProps = {},
                         saveBackProps = {},
                         cancelProps = {},
                         ...props
                       }) => (
  <Toolbar {...props} >
    {showSave && <SaveButton
      label="Create"
      redirect="edit"
      submitOnEnter={true}
      undoable={false}
      {...saveProps}
    />}

    {showSaveBack && <SaveButton
      label="Create and go back"
      redirect="list"
      submitOnEnter={false}
      undoable={false}
      variant="flat"
      {...saveBackProps}
    />}

    {showCancel && <CancelButton {...cancelProps}/>}
  </Toolbar>
);

const EditToolbar = ({
                       showSave = true,
                       showSaveBack = true,
                       showDelete = true,
                       showCancel = true,
                       saveProps = {},
                       saveBackProps = {},
                       deleteProps = {},
                       cancelProps = {},
                       ...props
                     }) => (
  <Toolbar {...props} >
    {showSave && <SaveButton
      label="Save"
      redirect="edit"
      submitOnEnter={true}
      {...saveProps}
    />}

    {showSaveBack && <SaveButton
      label="Save and go back"
      redirect="list"
      submitOnEnter={false}
      variant="flat"
      {...saveBackProps}
    />}

    {showDelete && <DeleteButton {...deleteProps}/>}

    {showCancel && <CancelButton {...cancelProps}/>}
  </Toolbar>
);