import React from "react";
import {
  ChipField,
} from "react-admin";
import Icon from '@material-ui/icons/NotInterested';

const UGroupChip = ({record, classes: c, ...props}) => {
  const rec = {
    __chip: <span title={record.description}>{record.name}</span>,
    ...record
  };
  const inactiveIcon = !record.active ? <Icon title='Group is inactive'/> : null;
  return <ChipField source="__chip" record={rec} avatar={inactiveIcon} {...props}/>;
};

export default UGroupChip;
