import React from "react";
import {
  Create,
} from "react-admin";
import NoopComponent from "common/noop-component/NoopComponent";
import UGroupAttachUsersForm from "./UGroupAttachUsersForm";
import CardContent from '@material-ui/core/CardContent';

export default ({record, ...props}) => (
  <div>
    <CardContent>
      <p>Attach users to add to the group. Use one of the options or all of them together.</p>
      <ul>
        <li>Select users via Reference input</li>
        <li>Import CSV file. Only first column is read, which is a user ID. No headers.</li>
        <li>Paste CSV contents</li>
      </ul>
      <p>Update mode:</p>
      <ul>
        <li>Append: just add new users to the group, ignoring those who are already attached.</li>
        <li>Replace: detach all users first and then attach new users.</li>
      </ul>
    </CardContent>

    <Create {...props}
            title={<NoopComponent/>}
            resource={`ugroups/${record.id}/users`}
    >
      <UGroupAttachUsersForm ugroup={record}/>
    </Create>
  </div>
);
