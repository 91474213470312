import React from 'react';
import {Resource} from 'react-admin'
import Icon from '@material-ui/icons/Announcement';
import AnnouncementsList from "../components/announcements/AnnouncementsList";
import AnnouncementsCreate from "../components/announcements/AnnouncementsCreate";
import AnnouncementsEdit from "../components/announcements/AnnouncementsEdit";

export default (permissions) => (
  [
    permissions['admin.announcements']
      ? <Resource name='announcements'
                  icon={Icon}
                  list={AnnouncementsList}
                  edit={permissions['admin.announcements.manage'] ? AnnouncementsEdit : null}
                  create={permissions['admin.announcements.manage'] ? AnnouncementsCreate : null}/>
      : <Resource name='announcements'/>,

    <Resource name='announcement_users'/>
  ]
);