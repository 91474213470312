import React from "react";
import {
  LongTextInput,
  TextInput,
  BooleanInput,
  SimpleForm,
} from "react-admin";
import {makeAsyncValidate} from "common/forms/Utils";
import CustomToolbar from "common/forms/CustomToolbar";

export default ({action, classes: c, ...props}) => (
  <SimpleForm
    {...props}
    toolbar={<CustomToolbar/>}
    asyncValidate={makeAsyncValidate(action)}
  >
    <TextInput source="name"/>
    <LongTextInput source="description"/>
    <BooleanInput source="active" defaultValue={true}/>
  </SimpleForm>
);