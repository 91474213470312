import React from 'react';
import { Admin, Resource } from 'react-admin';

import Dashboard from 'resources/dashboard';
import authProviderHive from 'auth/authProviderHive';
import authProviderIdp from 'auth/authProviderIdp';
import multiDataProvider from 'providers/multiDataProvider';
import LoginPage from 'components/auth/LoginPage';
import LoginPageIdp from 'components/auth/idp/LoginPageIdp';
import UserEdit from 'components/users/Edit';
import UsersList, { UsersIcon } from 'resources/users';
import FarmEdit from 'components/farms/Edit';
import FarmsList, { FarmsIcon } from 'resources/farms';
import CoinpaymentsList, { CoinpaymentsIcon } from 'resources/coinpayments';
import {DepositWalletsList, DepositWalletsIcon } from 'resources/deposit_wallets';
import UserEventsList, { UserEventsIcon }  from 'resources/user_events';
import WorkersList, { WorkersIcon } from 'resources/workers';
import WalletsList, { WalletsIcon } from 'resources/wallets';
import FarmEventsList, { FarmEventsIcon }  from 'resources/farm_events';
import AclList, { AclIcon }  from 'resources/acl';
import TransactionsList, { TransactionsIcon } from 'resources/transactions';
import { ApiKeysList, ApiKeysIcon, ApiKeysShow } from 'resources/api_keys';
import TokensList, { TokensIcon, TokensShow } from 'resources/tokens';
import AccessLogList, { AccessLogIcon } from 'resources/access_log';
import {PermissionsIcon, PermissionsList, PermissionCreate, PermissionEdit } from 'resources/permissions';
import {RolesIcon, RolesList, RoleCreate, RoleEdit } from 'resources/roles';
import Announcements from 'resources/announcements';
import UGroups from 'resources/ugroups';
import {SchedulesList, SchedulesIcon} from 'resources/schedules';
import {TagsList, TagsIcon} from 'resources/tags';
import { AlgosList, AlgosIcon } from 'resources/algos';
import { CoinsList, CoinsIcon } from 'resources/coins';
import TotalStats, { TotalStatsIcon } from 'resources/totalstats';
import { ReferralsList, ReferralsShow, ReferralsIcon } from 'resources/referrals';
import { WorkerShow } from "components/workers/Show";
import { TagShow } from "components/tags/Show";
import Settings, {SettingsIcon} from 'resources/settings';
import {GethView, GethIcon} from 'resources/geth';
import theme from './theme';
import {CONFIG} from "./config";
import {createBrowserHistory as createHistory} from 'history';

const App = () => {
  const title = `${CONFIG.name} Admin`;

  if (window && window.document) {
    window.document.title = title;
  }

  const authProvider = CONFIG.useHiveonId ? authProviderIdp : authProviderHive;
  const loginPage = CONFIG.useHiveonId ? LoginPageIdp : LoginPage;

  const history = CONFIG.useHisoryRouter ? createHistory() : null;

  return <Admin
    title={ title }
    theme={ theme }
    dashboard={ Dashboard }
    dataProvider={ multiDataProvider }
    authProvider={ authProvider }
    loginPage={ loginPage }
    history={ history }
  >
    { permissions => [

      permissions['admin.users']
        ? <Resource name='users' list={ UsersList } icon={ UsersIcon } edit={ UserEdit } />
        : <Resource name='users' />, // simple resource is needed for reference fields

      permissions['admin.farms']
        ? <Resource name='farms' list={ FarmsList } icon={ FarmsIcon } edit={ FarmEdit } />
        : <Resource name='farms' />, // simple resource is needed for reference fields

      permissions['admin.workers']
        ? <Resource name='workers' list={ WorkersList } show={ WorkerShow } icon={ WorkersIcon } />
        : <Resource name='workers' />, // simple resource is needed for reference fields

      permissions['admin.wallets']
        ? <Resource name='wallets' list={ WalletsList } icon={ WalletsIcon } />
        : null,

      permissions['admin.user_events']
        ? <Resource name='user_events' list={ UserEventsList } icon={ UserEventsIcon } />
        : null,

      permissions['admin.farm_events']
        ? <Resource name='farm_events' list={ FarmEventsList } icon={ FarmEventsIcon } />
        : null,

      permissions['admin.acl']
        ? <Resource name='acl' list={ AclList } icon={ AclIcon } options={{label: 'ACL'}} />
        : null,

      permissions['admin.coinpayments']
        ? <Resource name='coinpayments' list={ CoinpaymentsList } icon={ CoinpaymentsIcon } />
        : null,

      permissions['admin.transactions']
        ? <Resource name='transactions' list={ TransactionsList } icon={ TransactionsIcon } />
        : null,

      permissions['admin.api_keys']
        ? <Resource name='api_keys' list={ ApiKeysList } icon={ ApiKeysIcon } show={ ApiKeysShow } />
        : null,

      permissions['admin.tokens']
        ? <Resource name='tokens' list={ TokensList } icon={ TokensIcon } show={ TokensShow } />
        : null,

      permissions['admin.schedules']
        ? <Resource name='schedules' list={ SchedulesList } icon={ SchedulesIcon } />
        : null,

      permissions['admin.tags']
        ? <Resource name='tags' list={ TagsList } icon={ TagsIcon } show={ TagShow } />
        : <Resource name='tags'/>, // simple resource is needed for reference fields

      permissions['admin.accesslog']
        ? <Resource name='accesslog' list={ AccessLogList } icon={ AccessLogIcon } options={ {label: 'Access log'} } />
        : null,

      permissions['admin.algos']
        ? <Resource name='algos' list={ AlgosList } icon={ AlgosIcon } />
        : null,

      permissions['admin.coins']
        ? <Resource name='coins' list={ CoinsList } icon={ CoinsIcon } />
        : null,

      permissions['admin.deposit_addresses']
        ? <Resource name='deposit_addresses' list={DepositWalletsList} icon={DepositWalletsIcon}/>
        : null,

      permissions['admin.geth']
        ? <Resource name='geth' list={GethView} icon={GethIcon} options={{label: 'Geth'}}/>
        : null,

      <Resource name='geth/withdrawals'/>,

      permissions['admin.referral_payout']
        ? <Resource name='referrals' list={ ReferralsList } show={ ReferralsShow } icon={ ReferralsIcon } options={ {label: 'Referrals payout'} } />
        : null,

      <Resource name='referrals/payouts'/>,

      permissions['admin.total_stats']
        ? <Resource name='total_stats' list={ TotalStats } icon={ TotalStatsIcon } options={ {label: 'Total Stats'} } />
        : null,

      permissions['admin.roles']
        ? <Resource name='roles' list={RolesList} icon={RolesIcon}
                    edit={permissions['admin.roles.manage'] ? RoleEdit : null}
                    create={permissions['admin.roles.manage'] ? RoleCreate : null}/>
        : <Resource name='roles'/>,

      permissions['admin.roles']
        ? <Resource name='permissions' list={PermissionsList} icon={PermissionsIcon}
                    edit={permissions['admin.roles.manage'] ? PermissionEdit : null}/>
        : <Resource name='permissions'/>,

      ...Announcements(permissions),
      ...UGroups(permissions),

      permissions['admin.settings']
        ? <Resource name='settings' list={Settings} icon={SettingsIcon} title='Settings'/>
        : null,

    ] }
  </Admin>
};

export default App;
