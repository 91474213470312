import React from "react";
import {Filter, TextInput} from "react-admin";

export default (props) => (
  <Filter {...props}>
    <TextInput source='user_id' label='ID' alwaysOn/>
    <TextInput source='name' alwaysOn/>
    <TextInput source='login' alwaysOn/>
    <TextInput source='email' alwaysOn/>
    <TextInput source='attached_at' alwaysOn/>
  </Filter>
);