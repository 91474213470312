import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	Filter,
	TextInput,
	BooleanField,
	NullableBooleanInput,
	ChipField, ReferenceArrayField,
} from 'react-admin';
import Icon from '@material-ui/icons/MonetizationOn';
import JsonField from "../fields/JsonField";
import SortedSingleFieldList from "../common/lists/SortedSingleFieldList";

export const CoinsIcon = Icon;

const CoinsFilter = (props) => (
	<Filter {...props}>
		<TextInput label="ID" source="id" alwaysOn />
		<TextInput label="Symbol" source="symbol" alwaysOn />
		<TextInput label="Name" source="name" alwaysOn />
		<NullableBooleanInput label="Active" source="active" alwaysOn />
	</Filter>
);

export const CoinsList = (props) => (
	<List title="Coins"
	      {...props}
	      perPage={100}
	      filters={<CoinsFilter />}
	      sort={{field: 'sort_order', order: 'ASC'}}
        bulkActionButtons={ false }
	>
		<Datagrid>
			<TextField source="id" />
			<TextField source="symbol" />
			<TextField source="name" />
			<TextField source="sort_order" />
			<BooleanField source="active" />
			<JsonField source="props" sortable={false}/>
			<ReferenceArrayField label="Algos" reference="algos" source="algo_ids" sortable={false}>
				<SortedSingleFieldList sortBy="sort_order" sortOrder="desc">
					<ChipField source="name"/>
				</SortedSingleFieldList>
			</ReferenceArrayField>
		</Datagrid>
	</List>
);
