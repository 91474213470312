import React from 'react';
import { Notification, defaultTheme} from 'react-admin';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/LockOutline';


import LoginForm from "./LoginForm"


const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(https://source.unsplash.com/collection/2473400/1600x900)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary[500],
    },
});

const sanitizeRestProps = ({
   classes,
   className,
   location,
   title,
   array,
   theme,
   staticContext,
   ...rest
}) => rest;



const LoginPage = ({ classes, className, loginForm, ...rest }) => (
    <div
        className={classnames(classes.main, className)}
        {...sanitizeRestProps(rest)}
    >
        <Card className={classes.card}>
            <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                    <LockIcon />
                </Avatar>
            </div>
            {loginForm}
        </Card>
        <Notification />
    </div>
);

LoginPage.propTypes = {
    className: PropTypes.string,
    authProvider: PropTypes.func,
    classes: PropTypes.object,
    input: PropTypes.object,
    meta: PropTypes.object,
    previousRoute: PropTypes.string,
    loginForm: PropTypes.element,
};

LoginPage.defaultProps = {
    theme: defaultTheme,
    loginForm: <LoginForm />,
};

export default withStyles(styles)(LoginPage);
