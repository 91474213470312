import {Show, Tab, TabbedShowLayout, TabbedShowLayoutTabs} from "react-admin";
import React from "react";
import AnnouncementUsersList from "../announcement_users/AnnouncementUsersList";
import SubListWrapper from "../../common/lists/SubListWrapper";
import AnnouncementEditAttributes from "./edit/AnnouncementEditAttributes";

const EditAnnouncement = (props) => {
  const {
    record,
    resource,
    basePath,
  } = props;
  const tabsProps = {record, resource, basePath};

  return <TabbedShowLayout {...tabsProps} tabs={<TabbedShowLayoutTabs variant='scrollable'/>}>
    <Tab label="Edit">
      <AnnouncementEditAttributes {...props}/>
    </Tab>

    <Tab label="Users" path="users">
      <SubListWrapper>
        <AnnouncementUsersList {...props}/>
      </SubListWrapper>
    </Tab>
  </TabbedShowLayout>
};

export default (props) => {
  return <Show actions={null} {...props}>
    <EditAnnouncement {...props}/>
  </Show>;
};
