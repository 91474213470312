import React from "react";
import {
  Datagrid,
  DateField,
  TextField,
} from "react-admin";
import UserEmailField from "fields/UserEmailField";
import UserReference from "../../users/UserReference";

export default (props) => (
  <Datagrid {...props}>
    <TextField source='user_id' label='ID'/>
    <UserReference label='Login'>
      <TextField source='login'/>
    </UserReference>
    <TextField source='name'/>
    <UserEmailField source='email'/>
    <DateField source='attached_at' showTime locales='uk-UA'/>
  </Datagrid>
);
