import React from "react";
import {
  Show,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
} from "react-admin";
import SubListWrapper from "../../common/lists/SubListWrapper";
import UGroupUsersList from "../ugroup_users/UGroupUsersList";
import UGroupShowAttributes from "./show/UGroupShowAttributes";

export default (props) => (
  <Show actions={null} {...props}>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant='scrollable'/>}>
      <Tab label="Info">
        <UGroupShowAttributes {...props}/>
      </Tab>

      <Tab label="Users" path="users">
        <SubListWrapper>
          <UGroupUsersList {...props} editable={false}/>
        </SubListWrapper>
      </Tab>
    </TabbedShowLayout>
  </Show>
);