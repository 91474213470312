import React from "react";
import {
  BooleanField,
  Datagrid,
  ShowButton,
  EditButton,
  DeleteButton,
  TextField,
  DateField,
} from "react-admin";

export default ({consumer, classes: c, permissions: p, ...props}) => (
  <Datagrid {...props}>
    <TextField source='id'/>
    <TextField source='name'/>
    <TextField source='description'/>
    <TextField source='users_count'/>
    <BooleanField source='active'/>
    <BooleanField source='is_system' label='System'/>
    <TextField source='alias'/>
    <DateField source='created_at' showTime locales='uk-UA'/>
    {(p && p['admin.ugroups.manage'])
      ? <EditButton/>
      : <ShowButton/>}
    {p && p['admin.ugroups.manage'] && <Del/>}
  </Datagrid>
);

const Del = (props) => {
  const {record: {is_system: isSystem}} = props;
  if (isSystem) {
    return null;
  }
  return <DeleteButton {...props}/>
};