import React from 'react';
import { Notification, defaultTheme} from 'react-admin';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/LockOutline';
import Button from '@material-ui/core/Button';
import {userLogin} from 'react-admin';
import {connect} from 'react-redux';
import CardActions from '@material-ui/core/CardActions';

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(https://source.unsplash.com/collection/2473400/1600x900)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary[500],
    },
    button: {
        width: '100%',
    },
});

const sanitizeRestProps = ({
   classes,
   className,
   location,
   title,
   array,
   theme,
   staticContext,
   userLogin,
   ...rest
}) => rest;


class LoginPageIdp extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        authProvider: PropTypes.func,
        classes: PropTypes.object,
        input: PropTypes.object,
        meta: PropTypes.object,
        previousRoute: PropTypes.string,
    }

    static defaultProps = {
        theme: defaultTheme,
    }

    handleLoginClick = () => {
        const {userLogin} = this.props;
        userLogin();
    }

    render() {
        const {classes, className, ...rest} = this.props;

        return <div
          className={classnames(classes.main, className)}
          {...sanitizeRestProps(rest)}
        >
            <Card className={classes.card}>
                <div className={classes.avatar}>
                    <Avatar className={classes.icon}>
                        <LockIcon/>
                    </Avatar>
                </div>
                <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={this.handleLoginClick}>
                    Login using Hiveon ID
                </Button>
                </CardActions>
            </Card>
            <Notification/>
        </div>
    }
}

export default withStyles(styles)(connect(null, {userLogin})(LoginPageIdp));
