import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

const DropProps = ({children, drop = [], ...props}) => (
  React.Children.map(children, field => (
    React.cloneElement(field, _.omit(props, drop))
  ))
);

DropProps.propTypes = {
  drop: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default DropProps;