import {AUTH_LOGIN, AUTH_CHECK, AUTH_ERROR, AUTH_LOGOUT, AUTH_GET_PERMISSIONS} from 'react-admin';
import dataProvider, {GET, POST, HttpError} from 'providers/myRestProvider';
import Cookies from "js-cookie";
import {getCurrentUser, getPermissions, setCurrentUser} from "./user";

export default function authProviderHive(type, params) {
  if (type === AUTH_LOGIN) {
    console.log(type);
    const {username, password, twofa_code, remember} = params;
    const data = {login: username, password, twofa_code, remember};

    return dataProvider(POST, 'auth/login', {data})
      .then(({access_token, user}) => {
        localStorage.setItem('token', access_token);
        setCurrentUser(user);
        return true;
      });
  }

  else if (type === AUTH_CHECK) {
    console.log(type);
    return getCurrentUser() ? Promise.resolve() : Promise.reject();
  }

  else if (type === AUTH_ERROR) {
    const {status} = params;
    console.log(type, status, params);

    if (status !== 401 && status !== 403) {
      // we're not interested in other errors
      return Promise.resolve();
    }

    if (!getCurrentUser()) {
      return Promise.reject();
    }

    return Promise.resolve();
  }

  else if (type === AUTH_LOGOUT) {
    console.log(type);

    if (!getCurrentUser()) {
      return Promise.resolve();
    }

    return dataProvider(POST, 'auth/logout').then(() => {
      localStorage.removeItem('token');
      setCurrentUser(null);
      return true;
    });
  }

  else if (type === AUTH_GET_PERMISSIONS) {
    console.log(type);
    const permissions = getPermissions();
    if (permissions) {
      return Promise.resolve(permissions);
    }
    return fetchUser().then(getPermissions);
  }

  return Promise.resolve();
}

export function fetchUser() {
  const mainCookieKey = 'jwtt';

  if (!localStorage.getItem('token') && Cookies.get(mainCookieKey)) {
    localStorage.setItem('token', Cookies.get(mainCookieKey))
  }

  return dataProvider(GET, 'auth/check')
    .then(response => {
      const {user} = response || {};
      setCurrentUser(user);
      return Promise.resolve();
    })
    .catch(e => {
      if (e instanceof HttpError && e.status === 401) {
        // Unauthenticated error
        console.error('auth/check failed', e);
        return Promise.reject(e);
      }
      // maybe server error or something other not related to auth
      return Promise.resolve();
    });
}
