import React from "react";
import {
  SimpleShowLayout,
  TextField,
} from "react-admin";
import YesNoField from "fields/YesNoField";
import AdaptiveLayout from "common/layout/AdaptiveLayout";

export default (props) => (
  <AdaptiveLayout>
    <SimpleShowLayout {...props}>
      <TextField source='id'/>
      <TextField source='name'/>
      <TextField source='description'/>
    </SimpleShowLayout>

    <SimpleShowLayout {...props}>
      <YesNoField source='active'/>
    </SimpleShowLayout>
  </AdaptiveLayout>
)
