import Papa from "papaparse";

/**
 * Parses CSV file with and returns rows in promise
 * @param {File|string} csv CSV file or contents
 * @param {object} options Options for Papa Parse library
 * @return {Promise<Array[]>} Array of rows
 * @see https://www.papaparse.com/docs#config
 */
export const parseFile = (csv, options = {}) => {
  return new Promise((resolve, reject) => {
    Papa.parse(csv, {
      delimiter: ',', // set delimiter because auto mode fails when csv contains one column
      skipEmptyLines: true,
      ...options,
      complete: (result) => {
        const {data, errors} = result;
        if (errors.length) {
          const {row, message} = errors[0];
          reject(`Error in line ${row}: ${message}`);
        } else {
          resolve(data);
        }
      },
      error: (error) => {
        reject(error);
      }
    });
  })
};