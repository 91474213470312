import React from "react";
import {
  Show,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
} from "react-admin";
import UGroupEditAttributes from "./edit/UGroupEditAttributes";
import UGroupAttachUsers from "./edit/UGroupAttachUsers";
import SubListWrapper from "common/lists/SubListWrapper";
import UGroupUsersList from "components/ugroup_users/UGroupUsersList";

const EditGroup = (props) => {
  const {
    record,
    resource,
    basePath,
    record: {is_system: isSystem},
  } = props;
  const canEditUsers = !isSystem;
  const tabsProps = {record, resource, basePath};

  return <TabbedShowLayout {...tabsProps} tabs={<TabbedShowLayoutTabs variant='scrollable'/>}>
    <Tab label="Edit">
      <UGroupEditAttributes {...props}/>
    </Tab>

    {canEditUsers &&
      <Tab label="Attach users" path="attach">
        <UGroupAttachUsers {...props}/>
      </Tab>
    }

    <Tab label="Users" path="users">
      <SubListWrapper>
        <UGroupUsersList {...props} editable={canEditUsers}/>
      </SubListWrapper>
    </Tab>
  </TabbedShowLayout>
};

export default (props) => {
  return <Show actions={null} {...props}>
    <EditGroup {...props}/>
  </Show>;
};