import React from "react";
import {
  Filter,
  NullableBooleanInput,
  TextInput,
} from "react-admin";

export default (props) => (
  <Filter {...props}>
    <TextInput label='ID' source='id' alwaysOn/>
    <TextInput label='Name' source='name' alwaysOn/>
    <NullableBooleanInput label='Active' source='active' alwaysOn/>
    <NullableBooleanInput label='System' source='is_system' alwaysOn/>
  </Filter>
);