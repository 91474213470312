import React from "react";
import {List} from "react-admin";
import {CustomPagination} from "../../common/pagination/CustomPagination";
import AnnouncementsFilter from "./list/AnnouncementsFilter";
import AnnouncementsGrid from "./list/AnnouncementsGrid";

export default ({permissions, ...props}) => {
  return <List
    title="Announcements"
    {...props}
    sort={{field: 'id', order: 'ASC'}}
    pagination={<CustomPagination/>}
    perPage={50}
    filters={<AnnouncementsFilter/>}
    bulkActionButtons={false}
  >
    <AnnouncementsGrid permissions={permissions}/>
  </List>
};