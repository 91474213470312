import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  ReferenceArrayField,
  ReferenceField,
  TextField
} from "react-admin";
import CustomSelectField from "../../../fields/CustomSelectField";
import SortedSingleFieldList from "../../../common/lists/SortedSingleFieldList";
import UserChip from "../../users/UserChip";
import UGroupChip from "../../ugroups/common/UGroupChip";
import {ANNOUNCEMENT_TYPES} from "../../../constants/types";
import UserAliasField from "../../../fields/UserAliasField";

const allTypes = Object.keys(ANNOUNCEMENT_TYPES).map(id => ({id: id, name: ANNOUNCEMENT_TYPES[id]}));

export default ({consumer, classes: c, permissions, ...props}) => {
  return <Datagrid {...props}>
    <TextField source='id'/>
    <TextField source='title'/>
    <CustomSelectField source='type' choices={allTypes}/>
    <BooleanField source='active'/>
    <DateField source='start_at' showTime locales='uk-UA'/>
    <DateField source='end_at' showTime locales='uk-UA'/>
    <BooleanField source='global'/>
    <ReferenceArrayField label="Users" reference="users" source="user_ids" sortable={false}>
      <SortedSingleFieldList sortBy="login">
        <UserChip/>
      </SortedSingleFieldList>
    </ReferenceArrayField>
    <ReferenceArrayField label="User Groups" reference="ugroups" source="ugroup_ids" sortable={false}>
      <SortedSingleFieldList sortBy="name">
        <UGroupChip/>
      </SortedSingleFieldList>
    </ReferenceArrayField>
    <ReferenceField reference="users" source="owner_id" allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>
    {permissions && permissions['admin.announcements.manage'] && <EditButton/>}
    {permissions && permissions['admin.announcements.manage'] && <DeleteButton/>}
  </Datagrid>
};