import React from "react";
import {
  LongTextInput,
  TextInput,
  BooleanInput,
  SimpleForm,
  SelectInput,
  DateTimeInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectArrayInput,
} from "react-admin";
import {makeAsyncValidate} from "common/forms/Utils";
import CustomToolbar from "common/forms/CustomToolbar";
import {userOptionRenderer} from "../../../fields/UserAliasField";
import {ugroupOptionRenderer} from "../../ugroups/common/UGroupAliasField";
import {ANNOUNCEMENT_TYPES} from "../../../constants/types";

const allTypes = Object.keys(ANNOUNCEMENT_TYPES).map(id => ({id: id, name: ANNOUNCEMENT_TYPES[id]}));

export default ({action, classes: c, ...props}) => (
  <SimpleForm {...props} toolbar={<CustomToolbar/>} asyncValidate={makeAsyncValidate(action)}>
    <TextInput source="title" fullWidth={true}/>
    <LongTextInput source="message"/>
    <SelectInput source="type" choices={allTypes}/>
    <TextInput source="url" label='Details URL' fullWidth={true}/>
    <DateTimeInput source="start_at"/>
    <DateTimeInput source="end_at"/>
    <BooleanInput source="active" defaultValue={true}/>
    <BooleanInput source="global"/>
    <ReferenceArrayInput label="Users" source="user_ids" reference="users" allowEmpty={true}>
      <AutocompleteArrayInput optionText={userOptionRenderer}/>
    </ReferenceArrayInput>
    <ReferenceArrayInput label="User Groups" source="ugroup_ids" reference="ugroups" allowEmpty={true}>
      <SelectArrayInput optionText={ugroupOptionRenderer}/>
    </ReferenceArrayInput>
    <LongTextInput source="meta"/>
  </SimpleForm>
);