import Permissions from "./Permissions";

let currentUser = null;
let permissions = null;

/**
 * Set current user to storage
 * @param {object|null} user
 */
export function setCurrentUser(user) {
  currentUser = user;
  permissions = user ? Permissions.newFromUser(user) : null;

  console.log('user=', user);
}

/**
 * Get current user from storage
 * @return {object|null}
 */
export function getCurrentUser() {
  return currentUser;
}

/**
 *
 * @return {Permissions|null}
 */
export function getPermissions() {
  return permissions;
}