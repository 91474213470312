import React from "react";
import {List} from "react-admin";
import {CustomPagination} from "common/pagination/CustomPagination";
import UGroupsFilter from "./list/UGroupsFilter";
import UGroupsGrid from "./list/UGroupsGrid";

export default ({permissions: p, ...props}) => (
  <List
    {...props}
    title="User Groups"
    sort={{field: 'id', order: 'ASC'}}
    pagination={<CustomPagination/>}
    perPage={50}
    filters={<UGroupsFilter/>}
    bulkActionButtons={false}
  >
    <UGroupsGrid permissions={p}/>
  </List>
);
