import myRestProvider from "./myRestProvider"

const customProviders = [];

export const registerProvider = (callback) => {
	customProviders.push({
		callback,
	});
};

const getCustomProvider = (type, resource, params) => {
	for (const p of customProviders) {
		const provider = p.callback(type, resource, params);
		if (provider) {
			return provider;
		}
	}
	return null;
}

export default (type, resource, params) => {
	const customProvider = getCustomProvider(type, resource, params);
	if (customProvider) {
		return customProvider(type, resource, params);
	}
	return myRestProvider(type, resource, params);
}