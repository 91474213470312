import {AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT} from 'react-admin';
import {HttpError} from 'providers/myRestProvider';
import {setCurrentUser} from "./user";
import authProviderHive, {fetchUser} from "./authProviderHive";

export default function authProviderIdp(type, params) {
  if (type === AUTH_LOGIN) {
    console.log(type);
    return fetchUser().catch(check401);
  }

  else if (type === AUTH_LOGOUT) {
    console.log(type);
    setCurrentUser(null);
    return Promise.resolve();
  }

  else if (type === AUTH_GET_PERMISSIONS) {
    return authProviderHive(type, params).catch(check401);
  }

  else {
    return authProviderHive(type, params);
  }
}

function check401(e) {
  if (e instanceof HttpError && e.status === 401) {
    const {redirect} = e.data || {};
    if (redirect) {
      console.log('redirect to ', redirect);
      window.location.href = redirect;
      return Promise.resolve();
    }
  }
  throw e;
}
