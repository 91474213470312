/**
 * This class is a wrapper on permissions object.
 * All property GETs are intercepted with get() method.
 * @see https://stackoverflow.com/a/43323115
 */
export default class Permissions {
  constructor(su, permissions) {
    this.su = su;
    // convert array of string to object keys
    this.permissions = (permissions || []).reduce((obj, val) => {
      obj[val] = true;
      return obj;
    }, {});
    return new Proxy(this, this);
  }

  static newFromUser(user) {
    const {su = false, permissions = []} = user;
    return new Permissions(su, permissions);
  }

  get(target, prop) {
    // if super admin - always return true
    // otherwise check permission key
    return this.su || this._checkPermission(prop);
  }

  /**
   *
   * @param {string} perm
   * @return {boolean}
   * @private
   */
  _checkPermission(perm) {
    if (!perm.endsWith('.*')) {
      return !!this.permissions[perm];
    }

    const match = perm.substr(0, perm.length - 2);

    return !!Object.keys(this.permissions).find(key => {
      return key === match || key.startsWith(`${match}.`);
    });
  }
}