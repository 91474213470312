function env(value, defaultValue = null) {
  if (value === null || value === undefined) {
    return defaultValue;
  }
  if (typeof value === "number" && isNaN(value)) {
    return defaultValue;
  }
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return value;
  }
}

const CONFIG = {
  brand: env(process.env.REACT_APP_BRAND, 'hive'),
  name: env(process.env.REACT_APP_BRAND_NAME, 'Hive OS'),
  appUrl: env(process.env.REACT_APP_URL, 'https://den.hiveos.farm'),
  apiUrl: env(process.env.REACT_APP_API_URL),
  productUrl: env(process.env.REACT_APP_PRODUCT_URL, 'https://the.hiveos.farm'),
  fiatCurrency: env(process.env.REACT_APP_FIAT_CURRENCY, 'USD'),
  useHiveonId: env(process.env.REACT_APP_USE_HIVEON_ID, false),

  useProxy: env(process.env.REACT_APP_USE_PROXY, false),
  proxyApiUrl: env(process.env.REACT_APP_PROXY_API_URL),

  useHisoryRouter: env(process.env.REACT_APP_USE_HISTORY_ROUTER, false),
};

if (!CONFIG.apiUrl) {
  CONFIG.apiUrl = CONFIG.appUrl;
}

if (CONFIG.useProxy) {
  if (!CONFIG.proxyApiUrl) {
    CONFIG.proxyApiUrl = CONFIG.apiUrl || CONFIG.appUrl;
  }
  CONFIG.apiUrl = '';
}

//console.log('CONFIG=', CONFIG);

module.exports = {
  CONFIG,
}
