import React from "react";
import {ListButton} from "react-admin";
import CancelIcon from '@material-ui/icons/Clear';

export default ({
                  handleSubmit,
                  handleSubmitWithRedirect,
                  invalid,
                  pristine,
                  saving,
                  submitOnEnter,
                  ...props
                }) => (
  <ListButton
    label="Cancel"
    icon={<CancelIcon/>}
    variant="flat"
    {...props}
  />
)