import React from "react";
import {
  FileInput,
  FileField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  RadioButtonGroupInput,
  BooleanInput,
  LongTextInput,
} from "react-admin";
import _ from "lodash";
import CustomSimpleForm from "common/forms/CustomSimpleForm";
import {registerProvider} from "providers/multiDataProvider";
import {createProvider, createHttpRequest} from "providers/myRestProvider";
import {userOptionRenderer} from "fields/UserAliasField";
import CustomToolbar from "common/forms/CustomToolbar";
import AddIcon from '@material-ui/icons/Add';
import {parseFile} from "utils/csv";

export default ({record, ugroup, ...props}) => (
  <CustomSimpleForm
    {...props}
    record={{
      update_mode: 'append',
    }}

    toolbar={<CustomToolbar
      showSaveBack={false}
      showCancel={false}
      saveProps={{label: 'Add users', icon: <AddIcon/>, redirect: false}}
    />}
  >
    <RadioButtonGroupInput source="update_mode" choices={[
      {id: 'append', name: 'Append'},
      {id: 'replace', name: 'Replace'},
    ]}/>

    <BooleanInput source="ignore_invalid"/>

    <ReferenceArrayInput label="Users" source="user_ids" reference="users" allowEmpty={true} fullWidth={true}>
      <AutocompleteArrayInput optionText={userOptionRenderer}/>
    </ReferenceArrayInput>

    <FileInput source="csv_file" accept="text/csv">
      <FileField source="src" title="title"/>
    </FileInput>

    <LongTextInput source="csv_contents"/>

  </CustomSimpleForm>
);

registerProvider((type, resource, params) => {
  const {data} = params;
  if (['CREATE', 'UPDATE'].includes(type) && (data.csv_file || data.csv_contents)) {
    return createProvider(createRequest);
  }
  return null;
});

const createRequest = (type, resource, params) => {
  const {
    csv_file: {rawFile} = {},
    csv_contents: csvContents,
    user_ids: userIds = [],
  } = params.data;

  const promises = [];

  if (rawFile) {
    delete params.data.csv_file;
    promises.push(parseFile(rawFile));
  }
  if (csvContents) {
    delete params.data.csv_contents;
    promises.push(parseFile(csvContents));
  }

  return Promise.all(promises).then((results) => {
    let allIds = userIds;

    const processResult = (data) => {
      const ids = data
        .filter(row => /^\d+$/.test(row[0].trim()))
        .map(row => +row[0]);
      allIds = _.union(allIds, ids);
    }

    if (rawFile) {
      processResult(results.shift());
    }
    if (csvContents) {
      processResult(results.shift());
    }

    params.data.user_ids = allIds.map(id => +id);
    return createHttpRequest(type, resource, params);
  });
};