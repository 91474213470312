import React from "react";
import {List} from "react-admin";
import {CustomPagination} from "../../common/pagination/CustomPagination";
import AnnouncementUsersGrid from "./list/AnnouncementUsersGrid";
import AnnouncementUsersFilter from "./list/AnnouncementUsersFilter";

export default ({permissions: p, editable, ...props}) => (
  <List
    {...props}
    title="Users"
    resource='announcement_users'
    basePath='/announcement_users'
    hasCreate={false}
    {...(!editable ? {bulkActionButtons: false} : {})}
    sort={{field: 'user_id', order: 'ASC'}}
    pagination={<CustomPagination/>}
    perPage={50}
    filter={{announcement_id: props.id}}
    filters={<AnnouncementUsersFilter/>}
  >
    <AnnouncementUsersGrid/>
  </List>
);