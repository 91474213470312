import React from "react";
import {Resource} from "react-admin";
import UGroupsIcon from '@material-ui/icons/Group';
import UGroupsList from '../components/ugroups/UGroupsList';
import UGroupsShow from '../components/ugroups/UGroupsShow';
import UGroupsCreate from '../components/ugroups/UGroupsCreate';
import UGroupsEdit from '../components/ugroups/UGroupsEdit';

export default (permissions) => (
  [
    permissions['admin.ugroups']
      ? <Resource name='ugroups'
                  options={{label: 'User Groups'}}
                  icon={UGroupsIcon}
                  list={UGroupsList}
                  // show={!permissions['admin.ugroups.manage'] ? UGroupsShow : null}
                  show={UGroupsShow}
                  edit={permissions['admin.ugroups.manage'] ? UGroupsEdit : null}
                  create={permissions['admin.ugroups.manage'] ? UGroupsCreate : null}/>
      : <Resource name='ugroups'/>,

    <Resource name='ugroup_users'/>
  ]
);