import React from "react";
import {ReferenceFieldView} from "ra-ui-materialui/lib/field/ReferenceField";

/*

This field is like ReferenceField but uses input record as reference, instead of fetching it from API.
This is useful when rendering users list (as subresource) and want to render a link to user page.

Usage example as grid column:

<UserReference label='Login'>
  <TextField source='login'/>
</UserReference>

 */

export default ({record, source = 'user_id', ...props}) => (
  <ReferenceFieldView referenceRecord={record} resourceLinkPath={`/users/${record[source]}`} {...props}/>
);